@import "../../variables";

.side-menu {
  background-color: rgba(71, 70, 75, 0.7);
  position: absolute;
  top: 0;
  width: 336px;
  height: 100%;
  z-index: 98;
  left: 0;
  transition: all 0.7s ease-out;

  .side-menu-bar {
    position: absolute;
    right: 0;
    height: 100%;
    width: 36px;
    float: right;
    cursor: pointer;
    display: inline;
    background-color: #30a3dc;
  }

  #sidemenuTitle {
    top: calc(45.3%);
    position: relative;
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 21px;
  }

  .sidemenuBarIcon {
    float: left;
    z-index: 99;
    display: block;
    top: calc(36.5%);
    position: relative;
    width: 140%;
    margin-left: 23px;
  }

  .sidemenuBarIcon.closed {
    transform: scaleX(-1);
    margin-left: 25px;
  }
}

.side-menu.closed {
  left: -300px;
}

.side-menu-left {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    float: left;
    display: inline;
}

.side-menu-button {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-weight: 600;
    cursor: pointer;
}

.buttonText {
  display: block;
  padding: 8px 10px 10px 24px;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  font-size: 19px;
}

.buttonSmallText {
  display: block;
  padding: 4px 5px 5px 12px;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
}

.buttonText {
  color: #b2b2b2;
}

.buttonSmallText {
  color: #b2b2b2;
}

.buttonText:hover {
  color: $primary;
}

.buttonSmallText:hover {
  color: $primary;
}

.side-menu-button.active span {
  color: $primary;
}

.side-menu-button-small.active span {
  color: $primary;
}

.logo {
  display: block;
  width: 220px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 80px;
  padding: auto;
}

.rectangle {
    position: relative;
    top: -31px;
    width: 19px;
    height: 19px;
    border-radius: 2px;
    float: right;
    clear: both;
    margin: 0px;
    display: block;
    background-image: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
}

.list {
    position: relative;
    padding-left: 15px;
    width: 75%;
    margin-right: 45px;
    overflow-y: auto;
    overflow: hidden;
    list-style: none;
}

.list-small {
  list-style: none;
  text-transform: none;
}

