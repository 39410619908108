#videoDiv {
  // position: absolute;
  // display: block;
  // background-color: yellow;
  // width: 960px;
  // height: 540px;
  // z-index: 105;
  // margin: auto;
  box-sizing: border-box;
  margin-bottom: 8px;
}

@keyframes grow {
  from {
    width: 0px; 
    height: 0px;
  }
  to {
    width: 960px; 
    height: 540px
  }
}

#videoCloseButton {
  position: absolute;
  display: inline-block;
  height: 35px;
  width: 35px;
  padding: 0px;
  border-radius: 6px;
  margin: 0px;
  margin-left: 6px;
  cursor: pointer;
}

#videoCloseIcon {
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
  margin: auto;
  margin-top: 10px;
}

#videoBorder {
  border-radius: 2px;
  padding: 5px 5px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  float: left;
  margin-bottom: 8px;
}

#videoDisplayContent {
  background-color: #ffffffaa;
  color: black;
  height: auto;
  font-size: 20px;
  padding: 8px;
  width: 100%;
  padding-bottom: 30px;
  box-sizing: border-box;
}

#videoDisplayHeader {
  font-family: BebasNeue-Regular;
  block-size: auto;
  display: block;
  color: #ffffff;
  font-size: 30px;
  height: 8%;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  clear: left;
}