#playButtonContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1c4f6955;
  z-index: 800;
}

#videoBorderSubtitle {
  position: fixed;
  width: 100%;
  height: auto;
  bottom: 100px;
  z-index: 2;
  opacity: 0.9;
  align-content: center;
  justify-content: center;
}

.reactPlayerIntro {
  margin-right: auto;
  margin-left: auto;
}
#playButton {
  background-image: none;
  background-color: #00000000;
  border-style: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

#playButton > img {
  max-width: 256px;
  display: block;
	margin: auto;
	opacity: 1;
  cursor: pointer;
}

#playButton > img:hover {
  max-width: 280px;
}

#startButton {
  position: absolute;
  width: 256px;
  height: 70px;
  bottom: 40px;
  right: 40px;
  background-color: rgb(204, 95, 100);
  border-style: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: xx-large;
  font-weight: bold;
}

#startButtonContainer {
  display: none;
  position: absolute;
  z-index: 104;
  bottom: 0;
  right: 0;
}

#logoContainer {
  position: absolute;
  left: 2.7%;
  top: 2.7%;
  z-index: 1000;
  display: block;
}

#logo {
  z-index: 1000;
  display: block;
  max-width: 250px;
}

#logoContainer2 {
  position: absolute;
  right: 2.7%;
  top: 2.7%;
  z-index: 1000;
  display: block;
}

#logo2 {
  z-index: 1000;
  display: block;
  max-width: 400px;
}