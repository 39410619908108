@font-face {
  font-family: 'BebasNeue-Regular';
  src: local('BebasNeue'), url(./../../../assets/fonts/BebasNeue-Regular.ttf) format('truetype');
}

$maxWidth: 1800px;
$padding: 16px;

$calcWidth: $maxWidth - 148px - $padding * 2;

#htmlElement {
  width: $maxWidth;
  height: 1400px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1050;
}

#imageContainer {
  width: $calcWidth;
  margin: 16px 0px 16px 2px;
  padding: $padding;
  height: $calcWidth * 0.5625;
  float: left;
  border-radius: 12px;
}

#imageDisplayHeader {
  font-family: BebasNeue-Regular;
  block-size: auto;
  display: block;
  color: #ffffff;
  font-size: 70px;
  height: 100px;
  margin: 16px 0px 16px 2px;
  padding: $padding;
  width: $maxWidth - 148px - $padding * 2;
  clear: left;
}

#imageDisplayContent {
  background-color: #ffffffaa;
  color: black;
  height: 100%;
  font-size: 45px;
  padding: $padding;
  width: $maxWidth - 148px - $padding * 2;
}

#closeButton {
  text-align: center;
  font-size: 90px;
  float: left;
  display: block;
  height: 90px;
  width: 90px;
  padding: $padding;
  border-radius: 20px;
  margin: 20px 2px 16px 16px;
}

#closeIcon {
  display: block;
  position: relative;
  width: 70px;
  height: 70px;
  margin: auto;
  margin-top: 10px;
}
