.headerButton {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-left: 9px;
  margin-right: 9px;
  cursor: pointer;
  opacity: 0.9;
  pointer-events: visible;
}

.headerButton:hover {
  opacity: 1;
}