#interactionmarkerBox {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 700px;
  height: 25px;
  z-index: 10002;
  display: flex;
  justify-content: flex-end;
}

.marker {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
}

.marker.focus {
  filter: drop-shadow(0px 0px 6px #30a3dc);
  opacity: 1;
}

#notDone {
  cursor: pointer;
  background: radial-gradient(ellipse at center, 
    rgba(grey, 0) 40%,
    rgba(white, .75) 45.5%);
}

#notDone:hover {
  background: radial-gradient(ellipse at center, 
  rgba(grey, 0) 40%,
  rgba(white, .9) 45.5%);
}