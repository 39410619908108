@import "./variables";

$theme-colors: (
  "primary": $primary,
  "danger": #ff4136
);

$btn-border-radius: (
    0.416666vw,
);

.App {
  text-align: center;
}

@font-face {
  font-family: "SourceSansPro-Regular";
  src: local('SourceSansPro-Regular'), url(./assets/fonts/SourceSansPro-Regular.ttf) format('truetype');
}

* {
  font-family: SourceSansPro-Regular;
  font-size: 0.86vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  user-select: none;
}