.mapContainer {
  position: absolute;
  z-index: 104;
  width: 290px;
  height: 290px;
  bottom: 20px;
  transition: all 0.7s ease-out;
}

.map {
  font-size: 24px!important;
}

g {
  font-size: 24px!important;
}

svg {
  overflow: visible;
}

text, tspan {
  font-size: 30px!important;
}

.mapContainer.closed {
  left: 60px
}

.mapContainer.open {
  left: 360px
}