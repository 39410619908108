body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

#preloader-modal.preloader-modal__complete .preloader-modal__ok, #preloader-modal .preloader-modal__spinner{
  display: inline-block;
  margin-bottom: 0.5em;
}

#preloader-modal.preloader-modal__complete .preloader-modal__spinner, #preloader-modal .preloader-modal__ok{
  display: none;
  margin-bottom: 0.5em;
}

#preloader-modal .btn{
  margin-top: 2em;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-dialog {
  position: relative;
  margin: 0.5rem;
  pointer-events: none;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  bottom: 0;
  z-index: 10005;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  align-content: flex-start;
}

.modal-content {
  display: flex;
  background-color: #30a3dc;
  flex-flow: column;
  align-items: center;
  align-content: flex-start;
  margin: auto;

  position: relative;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.vertical-align {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.modal-dialog .modal-content {
  height: 99.8vh;
  border-radius: 0;
}