#headerButtonBar {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 600px;
  height: 80px;
  z-index: 104;
  padding-right: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  background: radial-gradient(ellipse at right top, #00000081 0%, #ffffff00 70%);
}